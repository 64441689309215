jQuery(document).ready(function () {
    jQuery('.navbar-toggler').click(function () {
        if (jQuery(this).hasClass('collapsed')) {
            jQuery(this).removeClass('collapsed');
        }
        else {
            jQuery(this).addClass('collapsed')
        }
        jQuery(this).parents('header').find('.collapse').toggleClass('show')
    })

    // banner slider



    // header fixing 
    // scroll
    jQuery(window).scroll(function () {
        let headerh = headerHeight();
        var scroll = jQuery(window).scrollTop();
        if (scroll >= headerh) {
            jQuery("header").addClass("fixed");
        } else {
            jQuery("header").removeClass("fixed");
        }
    });

    let afterHeader = jQuery('header').next('section');
    afterHeader.addClass('header_height');

    // headerHeight

    if (jQuery('.map_holder').length) {
        let map = jQuery('.map_holder');
        let mapParent = map.parents('section');
        let mapBottomPos = mapParent.offset().top + mapParent.outerHeight();

        jQuery(window).on("load resize scroll", function (e) {
            headerSticky(map, headerHeight(), mapBottomPos);
        });

    }
    if (jQuery('.side_bar').length) {
        let sidebar = jQuery('.side_bar');
        let sidebarTop = sidebar.offset().top;;

        let newMaxHeight = 'calc(100% - ' + headerHeight() - 30 + 'px)';
        sidebar.css('max-height', newMaxHeight);

        jQuery(window).on("load resize scroll", function (e) {
            headerSticky(sidebar, headerHeight(), sidebarTop, true);

        });
    }
    jQuery(window).on("load ", function (e) {
        headerHeight();
    });

    // content height

    heightIdentifier('.pos_content2', false);
    heightIdentifier('.pos_content', true);


    // more less button

    jQuery(document).on('click', '.moreless-button:not(.active)', function () {
        jQuery(this).addClass('active');
        jQuery(this).parents('.more_content').find('.more_text').slideToggle(400);
        if (jQuery(this).text() == "Read More") {
            jQuery(this).text("Download the Letter here. ");
        }
    });



});
function headerHeight() {
    let headerHeight = jQuery('header').height();
    return headerHeight;
}
// sticky map
function headerSticky(map, headerHeight, mapBottomPos, sidebar = null) {

    let headerBottomPos = jQuery('header').offset().top + headerHeight;
    let scrollTop = jQuery(window).scrollTop() + headerHeight;

    if (scrollTop > mapBottomPos && !map.hasClass('fixed')) {
        map.addClass('fixed');
        map.css('top', headerHeight);


    }
    else if (scrollTop <= mapBottomPos && map.hasClass('fixed')) {
        map.removeClass('fixed');
    }
    if (sidebar) {
        let parentSectionbtmOffset = map.parents('section').offset().top + map.parents('section').outerHeight();
        let sidebarbtmOffset = map.outerHeight() + map.offset().top;
        console.log('sidebarbottomoffset', sidebarbtmOffset)

        if (sidebarbtmOffset > parentSectionbtmOffset) {
            map.removeClass('fixed');
        }
    }

}
function heightIdentifier(el, space) {
    let content = jQuery(el); // Convert the selector string to a jQuery object
    content.each(function (index) {
        let parent = jQuery(this).parents('section');
        let height = jQuery(this).outerHeight();
        let parent_top = parseInt(parent.css('padding-top'), 10);
        let parent_bottom = parseInt(parent.css('padding-bottom'), 10);
        let spacing = height - (parent_top + parent_bottom);
        let element = (parent.find('.overflow_scroll'));

        let total = (function () {
            if (space) {
                if (element.length) {
                    parent.find('.overflow_scroll').css('height', spacing)
                }
                else {
                    return height + parent_top + parent_bottom;
                }
            } else {
                if (element.length) {
                  
                    parent.find('.overflow_scroll').css('height', spacing)
                }
                else {
                    return height;
                }


            }

        })();
        parent.css('min-height', total);
    });
}

/*
jQuery(".wcsatt-options-prompt-label-one-time .wcsatt-options-prompt-action").text(jQuery(".wcsatt-options-prompt-label-one-time .wcsatt-options-prompt-action").text().replace("Purchase one time", "For one year only"));
jQuery(".wcsatt-options-prompt-label-subscription .wcsatt-options-prompt-action").text(jQuery(".wcsatt-options-prompt-label-subscription .wcsatt-options-prompt-action").text().replace("for 1 year", "per year"));
jQuery(".wcsatt-options-prompt-label-subscription .wcsatt-options-prompt-action").text(jQuery(".wcsatt-options-prompt-label-subscription .wcsatt-options-prompt-action").text().replace("for 1 blwyddyn", "y flwyddyn"));
*/

jQuery("body.lang_cy section.em-event-where h3").text(jQuery("body.lang_cy section.em-event-where h3").text().replace("Where", "Lle"));
jQuery("body.lang_cy section.em-event-when h3").text(jQuery("body.lang_cy section.em-event-when h3").text().replace("When", "Pryd"));

jQuery("body.lang_cy a.em-item-read-more.button").text(function(_, currentText) {
    return currentText.replace("More Info", "Mwy o Wybodaeth");
});
jQuery("body.lang_cy .em-event-meta-datetime").text(function(_, currentText) {
    return currentText.replace("All Day", "Drwy'r dydd");
});